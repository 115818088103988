<template>
        <a-layout style="height: 100%;">
            <a-layout-content>
                <a-card style="width: 100%; height: 49%" title="1、正向提示词">
                    <div>
                        <div style="margin-bottom:20px">
                            <a-textarea placeholder="正向提示词" :rows="3" v-model:value="postive_input_text['text']" />
                        </div>

                        <a-space :align="center" class="button-area" v-for="(button, index) in postive_buttons" :key="button.id">
                            <a-button type="primary" @click="openModal(index, 'positive')">
                                {{ button.text }}
                            </a-button>
                            <a-modal
                                :id="'modal-' + index"
                                v-model:visible="button.showModal"
                                :title="button.text"
                                centered 
                                scrollable>
                                <template #footer>
                                    <a-button key="back" @click="closeModal(index, 'positive')">返回</a-button>
                                    <a-button key="submit" type="primary" :loading="loading" @click="updateTextarea(index, 'positive')">确认</a-button>
                                </template>
                                <a-space :align="center" class="button-area" v-for="tag in button.tags" :key="tag.id">
                                    <a-checkable-tag :class="{ 'custom-tag-selected': tag.checked, 'custom-tag-unselected': !tag.checked }" v-model:checked="tag.checked" >{{ tag.text }}</a-checkable-tag>
                                </a-space>
                                
                            </a-modal>
                        </a-space>
                    </div>
                    <div style="margin-top: 15px;">
                        <a-button type="dashed" @click="copyToClipboard('positive')" :class="{ copied: isCopied }">
                            {{postive_input_text['copyButtonText']}}
                        </a-button>
                    </div>
                </a-card>
                
                <a-card style="width: 100%; height:49%" title="2、负向提示词">
                    <div>
                        <div style="margin-bottom:20px">
                            <a-textarea placeholder="负向提示词" :rows="3" v-model:value="negative_input_text['text']" />
                        </div>

                        <a-space :align="center" class="button-area" v-for="(button, index) in negative_buttons" :key="button.id">
                            <a-button type="primary" @click="openModal(index, 'negative')">
                                {{ button.text }}
                            </a-button>
                            <a-modal
                                :id="'modal-' + index"
                                v-model:visible="button.showModal"
                                :title="button.text"
                                centered 
                                scrollable>
                                <template #footer>
                                    <a-button key="back" @click="closeModal(index, 'negative')">返回</a-button>
                                    <a-button key="submit" type="primary" :loading="loading" @click="updateTextarea(index, 'negative')">确认</a-button>
                                </template>
                                <a-space :align="center" class="button-area" v-for="tag in button.tags" :key="tag.id">
                                    <a-checkable-tag :class="{ 'custom-tag-selected': tag.checked, 'custom-tag-unselected': !tag.checked }" v-model:checked="tag.checked" >{{ tag.text }}</a-checkable-tag>
                                </a-space>
                                
                            </a-modal>
                        </a-space>
                    </div>
                    <div style="margin-top: 15px;">
                        <a-button type="dashed" @click="copyToClipboard('negative')" :class="{ copied: isCopied }">
                            {{negative_input_text['copyButtonText']}}
                        </a-button>
                    </div>
                </a-card>
            </a-layout-content>
        </a-layout>
        

</template>

<script>

  import { Clipboard  } from 'v-clipboard'

  export default {
    name: "SearchPrompt",
    components: {
    },
    data() {
      
      return {
        postive_input_text:{
            text: '',
            isCopied: false,
            copyButtonText: '复制到剪切板',
        }, 
        negative_input_text:{
            text: '',
            isCopied: false,
            copyButtonText: '复制到剪切板',
        },
        postive_buttons:[
            {
                id:'1', 
                text:'风格渲染',
                showModal: false,
                tags: [
                    {id:1, text:'人像摄影', data:'portraits', checked:false},
                    {id:2, text:'风景摄影', data:'landscape', checked:false},
                    {id:3, text:'动漫', data:'anime', checked:false},
                    {id:4, text:'科幻', data:'sci-fi', checked:false},
                    {id:5, text:'摄影', data:'photography', checked:false},
                    {id:6, text:'插画', data:'illustration', checked:false},
                    {id:7, text:'概念艺术', data:'concept art', checked:false},
                    {id:8, text:'写实主义', data:'realism', checked:false},
                    {id:9, text:'水彩绘画', data:'watercolor painting', checked:false},
                    {id:10, text:'赛博朋克', data:'cyberpunk', checked:false},
                    {id:11, text:'水墨画', data:'wash painting', checked:false},
                    {id:12, text:'ArtStation', data:'trending on artstaion', checked:false},
                    {id:13, text:'Blender', data:'blender', checked:false},
                    {id:14, text:'Cinema 4D', data:'cinema 4d', checked:false},
                    {id:15, text:'Maya', data:'maya', checked:false},
                    {id:16, text:'V-Ray', data:',v-ray', checked:false},
                    {id:17, text:'虚幻引擎', data:'unreal engin', checked:false},
                    {id:18, text:'ZBrush', data:'zbrush', checked:false},
                    {id:19, text:'Octane Render', data:'octan render', checked:false}
                ]
            },
            {
                id:'2', 
                text:'细节修饰',
                showModal: false,
                tags: [
                    {id:1, text:'尖锐焦点', data:'sharp focus', checked:false},
                    {id:2, text:'柔和', data:'smooth', checked:false},
                    {id:3, text:'锐利', data:'sharp', checked:false},
                    {id:4, text:'哑光', data:'matte', checked:false},
                    {id:5, text:'超广角', data:'ultrawide shot', checked:false},
                    {id:6, text:'低角度', data:'low angle', checked:false},
                    {id:7, text:'高低对比', data:'high low', checked:false},
                    {id:8, text:'近距离肖像', data:'up close portraits of', checked:false},
                    {id:9, text:'升级处理', data:'upscalsed', checked:false},
                    {id:10, text:'壁纸', data:'wallpaper', checked:false},
                    {id:11, text:'电影感', data:'cinematic', checked:false},
                    {id:12, text:'背景虚化', data:'bokeh', checked:false},
                    {id:13, text:'胶片颗粒', data:'film grain', checked:false},
                    {id:14, text:'背景虚化', data:'blurry background', checked:false},
                    {id:15, text:'柯达胶片', data:'Kodak Portra 400', checked:false}
                ]
            },
            {
                id:'3', 
                text:'光线照明',
                showModal: false,
                tags: [
                    {id:1, text:'梦幻光线', data:'dreamatic lightning', checked:false},
                    {id:2, text:'环境光', data:'ambient lighting', checked:false},
                    {id:3, text:'光效', data:'light effect', checked:false},
                    {id:4, text:'上灯', data:'uplight', checked:false},
                    {id:5, text:'体积光', data:'volumetric lighting', checked:false},
                    {id:6, text:'情绪照明', data:'mood lighting', checked:false},
                    {id:7, text:'明亮', data:'bright', checked:false},
                    {id:8, text:'柔光', data:'soft light', checked:false},
                    {id:9, text:'柔和照明', data:'soft illumination', checked:false},
                    {id:10, text:'闪烁光线', data:'rays of shimmering light', checked:false},
                    {id:11, text:'工作室照明', data:'studio lighting', checked:false}
                ]
            },
            {
                id:'4', 
                text:'画质',
                showModal: false,
                tags: [
                    {id:1, text:'杰作', data:'masterpiece', checked:false},
                    {id:2, text:'最佳质量', data:'best quality', checked:false},
                    {id:3, text:'高清细节', data:'highly detailed', checked:false},
                    {id:4, text:'专业', data:'professional', checked:false},
                    {id:5, text:'优质', data:'beautiful', checked:false},
                    {id:6, text:'高分辨率', data:'highres', checked:false},
                    {id:7, text:'HDR', data:'HDR', checked:false},
                    {id:8, text:'超高清', data:'UHD', checked:false},
                    {id:9, text:'4K', data:'4K', checked:false},
                    {id:10, text:'8K', data:'8K', checked:false},
                    {id:11, text:'鲜艳色彩', data:'Vivid Colors', checked:false}
                ]
            },
        ],
        negative_buttons:[
            {
                id:'1',
                text:'人物相关',
                showModal: false,
                tags: [
                    {id:1, text:'不良手部', data:'Poorly Drawn Hands', checked:true},
                    {id:2, text:'额外手臂', data:'Extra Arms', checked:true},
                    {id:3, text:'额外手指', data:'Extra Fingers', checked:true},
                    {id:4, text:'过多手指', data:'Too Many Fingers', checked:true},
                    {id:5, text:'额外腿部', data:'Extra Legs', checked:true},
                    {id:6, text:'额外肢体', data:'Extra Limbs', checked:true},
                    {id:7, text:'融合手指', data:'Fused Fingers', checked:true},
                    {id:8, text:'长脖子', data:'Long Neck', checked:true},
                    {id:9, text:'身体变形', data:'Deformed Body Features', checked:true},
                    {id:10, text:'缺失手臂', data:'Missing Arms', checked:true},
                    {id:11, text:'缺失腿部', data:'Missing Legs', checked:true},
                    {id:12, text:'不良的脚', data:'Poorly Drawn Feet', checked:true},
                    {id:13, text:'克隆脸', data:'Cloned Face', checked:true},
                    {id:14, text:'额外肢体', data:'Extra Limbs', checked:true},
                    {id:15, text:'突变手', data:'Mutated Hands', checked:true},
                    {id:16, text:'毁容', data:',Disfigured', checked:true},
                    {id:17, text:'畸形', data:'Deformed', checked:true},
                    {id:18, text:'残缺', data:'Mutilated', checked:true},
                    {id:19, text:'脱水', data:'Dehydrated', checked:true},
                    {id:19, text:'不良脸部', data:'Poorly Drawn Face', checked:true}
                ]
            },
            {
                id:'2',
                text:'画面相关',
                showModal: false,
                tags: [
                    {id:1, text:'丑陋', data:'Ugly', checked:true},
                    {id:2, text:'比例失调', data:'Bad Proportions', checked:true},
                    {id:3, text:'平铺', data:'Tiling', checked:true},
                    {id:4, text:'病态', data:'Morbid', checked:true},
                    {id:5, text:'裁剪', data:'Cropped', checked:true},
                    {id:6, text:'突变', data:'Mutation', checked:true},
                    {id:7, text:'超出画面', data:'Body out of Frame, Out of Frame', checked:true},
                    {id:8, text:'重复', data:'Duplicate', checked:true},
                    {id:9, text:'截断', data:'Cut Off', checked:true},
                    {id:10, text:'错误', data:'Error', checked:true},
                    {id:11, text:'签名', data:'Signature', checked:true},
                    {id:12, text:'文字', data:'Text', checked:true},
                    {id:13, text:'糟糕艺术', data:'Bad Art', checked:true},
                    {id:14, text:'业余者', data:'Amateur', checked:true},
                    {id:15, text:'水印', data:'Watermark', checked:true},
                    {id:16, text:'比例失调', data:'Gross Proportions', checked:true},
                    {id:17, text:'构图不佳', data:'Bad Composition', checked:true}
                ]
            },
            {
                id:'3',
                text:'画质相关',
                showModal: false,
                tags: [
                    {id:1, text:'模糊', data:'Blurry', checked:true},
                    {id:2, text:'低对比度', data:'Low Contrast', checked:true},
                    {id:3, text:'曝光不足', data:'Underexposed', checked:true},
                    {id:4, text:'曝光过度', data:'Overexposed', checked:true},
                    {id:5, text:'JPEG伪像', data:'JPEG Artifacts', checked:true},
                    {id:6, text:'低质量', data:'Low Quality', checked:true},
                    {id:7, text:'低分辨率', data:'Low Resolution', checked:true},
                    {id:8, text:'低饱和度', data:'Low Saturation', checked:true}
                ]
            }
        ]
      };
    },
    methods: {
        openModal(index, type){
            if(type == 'negative'){
                this.negative_buttons[index]['showModal'] = true;
            }else{
                this.postive_buttons[index]['showModal'] = true;
            }
        },
        closeModal(index, type){
            if(type == 'negative'){
                this.negative_buttons[index]['showModal'] = false;
            }else{
                this.postive_buttons[index]['showModal'] = false;
                this.postive_buttons[index].tags
                  .filter(tag => tag.checked)
                  .map(tag => {
                    tag.checked=false;
                })
            }
            
        },
        joinText(text1, text2){
            if(text1.length > 0){
                return text1 + ',' + text2;
            }else{
                return text2;
            }
        },
        updateTextarea(index, type){
            var text = null;
            if(type == 'negative'){
                text = this.negative_buttons[index].tags
                  .filter(tag => tag.checked)
                  .map(tag => {
                    tag.checked=false;
                    return tag.data;
                   })
                  .join(',');
                this.negative_buttons[index].tags
                  .filter(tag => !tag.checked)
                  .map(tag => {
                    tag.checked=true;
                  });
                this.negative_input_text['text'] = this.joinText(this.negative_input_text['text'], text);
            }else{
                text = this.postive_buttons[index].tags
                  .filter(tag => tag.checked)
                  .map(tag => {
                    tag.checked=false;
                    return tag.data;
                   })
                  .join(',');
                this.postive_input_text['text'] = this.joinText(this.postive_input_text['text'], text);
            }
           
            this.closeModal(index, type);
        },
        copyToClipboard(type) {
            if(type == 'negative'){
                Clipboard.copy(this.negative_input_text['text'])

                this.negative_input_text['isCopied'] = true
                this.negative_input_text['copyButtonText'] = '已复制'

                // 切换按钮class，显示复制成功
                setTimeout(() => {
                    this.negative_input_text['isCopied'] = false
                    this.negative_input_text['copyButtonText'] = '复制到剪切板'
                }, 2000);
            }else{
                Clipboard.copy(this.postive_input_text['text'])

                this.postive_input_text['isCopied'] = true
                this.postive_input_text['copyButtonText'] = '已复制'

                // 切换按钮class，显示复制成功
                setTimeout(() => {
                    this.postive_input_text['isCopied'] = false
                    this.postive_input_text['copyButtonText'] = '复制到剪切板'
                }, 2000);
            }
            
        }
        
    }
  };
</script>

<style scoped>
.copied {
  background-color: green;
  color: white;
}

.custom-tag-selected {
  background-color: rgb(24, 144, 255);
  color: rgb(255, 255, 255);
  border:1px solid rgb(199, 198, 198);
}

.custom-tag-unselected:hover {
  background-color: rgba(199, 198, 198);
  color: rgb(255, 255, 255);
  border:1px solid rgb(199, 198, 198);
}

.custom-tag-unselected {
  background-color: rgba(244, 245, 247, 1);
  color: rgb(179, 179, 179);
  border:1px solid rgb(199, 198, 198);
}

</style>